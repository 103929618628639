/*!! Copyright : Emmanuel Verhanneman from iXpole 2018-2019 */
.has-update-behavior 
  input
    width                   100%
    &+ .state
      display               none
      opacity               0
      color                 white
      //transform translateX(-50%)
      &.showing
        display             initial
        animation-name      pushed
        animation-fill-mode forwards
        animation-duration  2s

      &.pushed
        text-align          center
        background          #8ee37d
        border-radius       50% 
        position            absolute
        right               0


@keyframes pushed
  0%
    transform           translateX(-50%) scale(1)
    opacity             1
  5%
    opacity             0.9
  10%
    opacity             0.8
  20%
    opacity             0.75
  30%
    transform           translateX(-50%) scale(1.2)
    opacity             0.7
  45%
    transform           translateX(-50%) scale(0.2)
    opacity             0.5
  75%
    opacity             0.4
  80%
    opacity             0.2
  100%
    transform           translateX(-50%) scale(0)
    opacity             0