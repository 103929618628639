
/* line 297 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */

/* line 1 : C:\gloups\node_modules\stylus\lib\functions\index.styl */

/* line 1 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
/*! Copyright : Emmanuel Verhanneman from iXpole 2018-2019 */

/* line 2 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */

/* line 3 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
.has-update-behavior input {
  width: 100%;
}

/* line 5 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
.has-update-behavior input+ .state {
  display: none;
  opacity: 0;
  color: #fff;
}

/* line 10 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
.has-update-behavior input+ .state.showing {
  display: initial;
  animation-name: pushed;
  animation-fill-mode: forwards;
  animation-duration: 2s;
}

/* line 16 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
.has-update-behavior input+ .state.pushed {
  text-align: center;
  background: #8ee37d;
  border-radius: 50%;
  position: absolute;
  right: 0;
}

/* line 24 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
@-moz-keyframes pushed {

/* line 25 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  0% {
    transform: translateX(-50%) scale(1);
    opacity: 1;
  }

/* line 28 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  5% {
    opacity: 0.9;
  }

/* line 30 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  10% {
    opacity: 0.8;
  }

/* line 32 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  20% {
    opacity: 0.75;
  }

/* line 34 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  30% {
    transform: translateX(-50%) scale(1.2);
    opacity: 0.7;
  }

/* line 37 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  45% {
    transform: translateX(-50%) scale(0.2);
    opacity: 0.5;
  }

/* line 40 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  75% {
    opacity: 0.4;
  }

/* line 42 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  80% {
    opacity: 0.2;
  }

/* line 44 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  100% {
    transform: translateX(-50%) scale(0);
    opacity: 0;
  }
}

/* line 24 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
@-webkit-keyframes pushed {

/* line 25 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  0% {
    transform: translateX(-50%) scale(1);
    opacity: 1;
  }

/* line 28 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  5% {
    opacity: 0.9;
  }

/* line 30 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  10% {
    opacity: 0.8;
  }

/* line 32 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  20% {
    opacity: 0.75;
  }

/* line 34 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  30% {
    transform: translateX(-50%) scale(1.2);
    opacity: 0.7;
  }

/* line 37 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  45% {
    transform: translateX(-50%) scale(0.2);
    opacity: 0.5;
  }

/* line 40 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  75% {
    opacity: 0.4;
  }

/* line 42 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  80% {
    opacity: 0.2;
  }

/* line 44 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  100% {
    transform: translateX(-50%) scale(0);
    opacity: 0;
  }
}

/* line 24 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
@-o-keyframes pushed {

/* line 25 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  0% {
    transform: translateX(-50%) scale(1);
    opacity: 1;
  }

/* line 28 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  5% {
    opacity: 0.9;
  }

/* line 30 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  10% {
    opacity: 0.8;
  }

/* line 32 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  20% {
    opacity: 0.75;
  }

/* line 34 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  30% {
    transform: translateX(-50%) scale(1.2);
    opacity: 0.7;
  }

/* line 37 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  45% {
    transform: translateX(-50%) scale(0.2);
    opacity: 0.5;
  }

/* line 40 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  75% {
    opacity: 0.4;
  }

/* line 42 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  80% {
    opacity: 0.2;
  }

/* line 44 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  100% {
    transform: translateX(-50%) scale(0);
    opacity: 0;
  }
}

/* line 24 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
@keyframes pushed {

/* line 25 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  0% {
    transform: translateX(-50%) scale(1);
    opacity: 1;
  }

/* line 28 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  5% {
    opacity: 0.9;
  }

/* line 30 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  10% {
    opacity: 0.8;
  }

/* line 32 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  20% {
    opacity: 0.75;
  }

/* line 34 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  30% {
    transform: translateX(-50%) scale(1.2);
    opacity: 0.7;
  }

/* line 37 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  45% {
    transform: translateX(-50%) scale(0.2);
    opacity: 0.5;
  }

/* line 40 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  75% {
    opacity: 0.4;
  }

/* line 42 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  80% {
    opacity: 0.2;
  }

/* line 44 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\input-on-blur-pushing.styl */
  100% {
    transform: translateX(-50%) scale(0);
    opacity: 0;
  }
}
